/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { dateFormat } from '../../../../../auth'

interface UserPlanDetail {
  startDate?: string
  endDate?: string
}

interface Plan {
  name: string
  days: number
}

interface Register {
  name: string
  mobile: number
  user_plan_details: UserPlanDetail[]
  plan_status: {
    plan_status: string
  }
}

interface Product {
  serialNo: number
  user_id: number
  razorpay_payment_id: string
  register: Register
}

interface Props {
  product: Product
}

const ProductStartInfoCell: FC<Props> = ({ product }) => {
  const startDate = product.register.user_plan_details[0]?.startDate
  return <div className='symbol-label'>{dateFormat(startDate)}</div>
}

const ProductEndInfoCell: FC<Props> = ({ product }) => {
  const endDate = product.register.user_plan_details[0]?.endDate
  return <div className='symbol-label'>{dateFormat(endDate)}</div>
}

const ProductStatusCell: FC<Props> = ({ product }) => {
  const status = product.register.plan_status.plan_status
  return (
    <div className='d-flex align-items-center'>
      <p>{status}</p>
    </div>
  )
}

export { ProductStartInfoCell, ProductEndInfoCell, ProductStatusCell }
