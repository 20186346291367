// @ts-nocheck
import {Column} from 'react-table'
import {ProductStartInfoCell, ProductEndInfoCell,ProductStatusCell} from './ProductInfoCell'

import {ProductCustomHeader} from './ProductCustomHeader'
import {Product} from '../../core/_models'

const EmployeeColumns: ReadonlyArray<Column<Product>> = [
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='S.No' className='min-w-125px' />
    ),
    accessor: 'serialNo',
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='UserId' className='min-w-125px' />
    ),
    accessor: 'user_id',
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Name' className='min-w-125px' />
    ),
    accessor: 'register.name',
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Mobile' className='min-w-125px' />
    ),
    accessor: 'register.mobile',
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Plan' className='min-w-125px' />
    ),
    accessor: (row) => row.register.user_plan_details[0]?.plan.name,
    id: 'plan', // Added id for this column
  },

  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Plan Days' className='min-w-125px' />
    ),
    accessor: (row) => row.register.user_plan_details[0]?.plan.days,
    id: 'planDays', // Added id for this column
  },

  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Receipt' className='min-w-125px' />
    ),
    accessor: 'razorpay_payment_id',
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Plan Start' className='min-w-125px' />
    ),
    accessor: 'user_plan_detail.userId',
    Cell: ({...props}) => <ProductStartInfoCell product={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Plan End' className='min-w-125px' />
    ),
    accessor: 'register.plan_end',
    Cell: ({...props}) => <ProductEndInfoCell product={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ProductCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    accessor: 'register.plan_status.plan_status',
    Cell: ({...props}) => <ProductStatusCell product={props.data[props.row.index]} />,
  },
]

export {EmployeeColumns}
