import axios, {AxiosResponse} from 'axios'
 
import {Product, ProdcutQueryResponse} from './_models'
import { getToken } from '../../../../auth'
import { log } from 'console'
 

//BASE_URL LINK
const API_URL = process.env.REACT_APP_API_URLS

//AuthVerify
var AuthVerify = getToken.call(this)

const getProduct = (query: string): Promise<ProdcutQueryResponse> => {
  return axios
    .get(`${API_URL}/listAllSubscription/query?${query}`,getToken())
    .then((response: AxiosResponse<ProdcutQueryResponse>) => {
      console.log("sub",response.data)
      return response.data
    })
}

export { getProduct, }
